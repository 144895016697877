/*
 |-----------------------------------------------------------------------------
 | components/molecules/Alert/Alert.tsx
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

import { FC, useState } from 'react';

// import { ray } from 'node-ray/web';

import * as IAlert from './types';

const Alert: FC<IAlert.IProps> = ({
	isDismissible,
	text,
	utilities = '',
	variant,
}) => {
	// ray('Debug molecule Alert:', {
	// 	isDismissible: isDismissible,
	// 	text: text,
	// 	utilities: utilities,
	// 	variant: variant,
	// }).blue();

	const [visible, setVisible] = useState<boolean>(true);

	if (!isDismissible) {
		setTimeout(function () {
			setVisible(false);
		}, 3000);
	}

	let variants;
	switch (variant) {
		case 'error':
			variants = `bg-red-300 border-red-500`;
			break;
		case 'success':
			variants = `bg-green-300 border-green-500`;
			break;
		case 'warning':
			variants = `bg-amber-300 border-amber-500`;
			break;
	}

	if (!visible) {
		return null;
	}

	return (
		<div
			className={`
				border-l-8
				border-l-solid
				flex
				items-center
				justify-between
				pl-4
				${isDismissible ? '' : 'p-3'}
				${variants}
				${utilities}
			`}
			data-testid="alert"
			role="alert"
		>
			<p
				className={`
					font-normal
					text-black
					text-base
					sm:text-sm
				`}
			>
				{text}
			</p>

			{isDismissible && (
				<button
					className={`
						h-11
						opacity-50
						relative
						transition-opacity
						w-11
						after:absolute
						after:bg-black
						after:h-6
						after:left-1/2
						after:top-1/2
						after:w-1
						before:absolute
						before:bg-black
						before:h-6
						before:left-1/2
						before:top-1/2
						before:w-1
						after:-translate-x-1/2
						after:-translate-y-1/2
						after:-rotate-45
						before:-translate-x-1/2
						before:-translate-y-1/2
						before:rotate-45
						hover:opacity-100
						disabled:opacity-25
					`}
					onClick={() => setVisible(false)}
					type="button"
				>
					<span className={`sr-only`}>Dismiss alert</span>
				</button>
			)}
		</div>
	);
};

export default Alert;
